<template>
  <page-layout class="ma-24">
    <!-- 搜索头 -->
    <search-header
      :schemaData="searchFileds"
      @getDataList="handleFormSearch"
      style="padding-left: 0px;"
    />
    <!-- 导出按钮 -->
    <div class="pa-1">
      <!-- <div></div> -->
      <div
        class="bg-white px-24 py-16 "
        style="border:1px solid #e8eaec;border-bottom:none;width: calc(100% - 2px);
    margin: 0 auto;"
      >
        <a-button
          v-if="$store.getters.roles.includes('ConsumerOrdersExport')"
          @click="exportVoucher"
          class="d-flex align-center"
        >
          <Icon name="icondaochu1" width="15" height="15"></Icon>
          <span class="ml-8">导出</span>
        </a-button>
      </div>
    </div>
    <!-- 列表 -->
    <div class="bg-white">
      <p-table
        rowKey="id"
        :dataSource="dataSource"
        class="bg-white"
        dataKey="modal"
      >
        <p-t-column type="seq" width="60" title="序号" />
        <p-t-column field="order_no" width="260" title="订单编号" />
        <p-t-column field="oil_name" min-width="120" title="油品类型" />
        <p-t-column field="driver_name" min-width="120" title="司机姓名" />
        <p-t-column field="driver_mobile" min-width="180" title="手机号" />
        <p-t-column field="station_name" min-width="240" title="加油站名称" />
        <p-t-column field="company_name" min-width="240" title="归属公司" />
        <p-t-column
          field="amount"
          min-width="120"
          title="订单金额（元）"
          v-slot="{ row }"
          >{{ (row.amount / 100).toFixed(2) }}</p-t-column
        >
        <p-t-column field="quantity" min-width="120" title="加油/气量" />
        <p-t-column field="sp_name" min-width="120" title="服务商" />
        <p-t-column field="status_desc" min-width="120" title="订单状态" />
        <p-t-column
          field="order_time"
          min-width="240"
          title="创建时间"
          v-slot="{ row }"
        >
          <span>{{
            formatToDateTime(row.order_time, "yyyy-MM-dd hh:mm:ss")
          }}</span>
        </p-t-column>
        <!-- <p-t-column field="order_count" min-width="120" title="开通时间" /> -->
        <p-t-column min-width="120" title="操作" fixed="right" v-slot="{ row }">
          <a-button
            type="link"
            style="padding:0;"
            @click="handleOrderDetail(row)"
            >详情</a-button
          >
        </p-t-column>
      </p-table>
    </div>
  </page-layout>
</template>
<script>
import { defineComponent, reactive } from "vue";
import { message } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { searchFileds } from "./constants/index";
import useSearchForm from "@/hooks/UseSearchForm";
import useSearch from "@/hooks/useSearch";
import {
  useGasOrderPageApi,
  useGasOrderPageExportApi,
  useEnumGasOilTypeListApi,
  useEnumServiceProvideListApi
} from "@/apis/oil";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default defineComponent({
  name: "consumer-orders",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const tableDataSource = reactive(getInitTable());
    //油品类型列表
    (async () => {
      const enumGasOilTypeList = useEnumGasOilTypeListApi();
      let gasOilTypeList = await enumGasOilTypeList();
      useSearch(searchFileds, gasOilTypeList, "oil_code");
    })();
    //服务商列表
    (async () => {
      const enumServiceProvideList = useEnumServiceProvideListApi();
      let serviceProvideList = await enumServiceProvideList();
      console.log(serviceProvideList);
      useSearch(searchFileds, serviceProvideList, "sp_code");
    })();

    //表格搜索相关
    const { filter, handleFormSearch } = useSearchForm(searchFileds);
    //表格数据接口
    const gasOrderPage = useGasOrderPageApi();
    const getConsultingList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        //路由默认值
        if (route.query) {
          console.log(route.query);
          Object.keys(route.query).forEach(item => {
            if (!filter.value[item]) {
              if (item === "driver_ids") {
                filter.value[item] = [route.query[item]];
              } else {
                filter.value[item] = route.query[item];
              }
            }
          });
        }
        const data = {
          ...filter.value,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        // data.driver_id = data.driver_id ? [data.driver_id] : data.driver_id;
        const res = await gasOrderPage(data);
        return { records: res.list, total: res.total };
      }
    });
    // 跳转到订单详情
    const handleOrderDetail = row => {
      console.log(row);
      let details = {
        id: row.id
      };
      router.push({
        path: "/oilCard/consumer-orders-detail",
        query: details
      });
    };
    // 导出数据
    const gasOrderPageExport = useGasOrderPageExportApi();
    const exportVoucher = async () => {
      // TODO  导出数据
      const data = {
        ...filter.value,
        export_flag: true,
        size: tableDataSource.meta.pageSize,
        page: tableDataSource.meta.currentPageNo
      };
      const res = await gasOrderPageExport(data);
      if (res) {
        message.success("导出成功，请前往下载中心查看");
      }
    };
    return {
      exportVoucher,
      searchFileds,
      handleOrderDetail,
      getConsultingList,
      handleFormSearch,
      dataSource: tableDataSource,
      formatToDateTime
    };
  }
});
</script>

/**
 * 搜索头
 */
//  消费订单
export const searchFileds = [
  {
    field: "order_no",
    label: "订单编号",
    type: "input",
    props: {
      placeholder: "请输入订单编号"
    },
    defaultValue: null,
    iw: 200
  },
  {
    field: "station_name",
    label: "加油站名称",
    type: "input",
    props: {
      placeholder: "请输入加油站名称"
    },
    defaultValue: null,
    iw: 200
  },
  {
    field: "sp_code",
    label: "服务商",
    type: "select",
    defaultValue: null,
    options: [],
    iw: 200
  },
  {
    field: ["start_time", "end_time"],
    label: "创建时间",
    type: "dateRange",
    props: {
      placeholder: ["请选择开始时间", "请选择结束时间"],
      dateFormat: "YYYY/MM/DD"
    },
    defaultValue: null,
    iw: 320
  },
  {
    field: "oil_code",
    label: "油品类型",
    type: "select",
    defaultValue: null,
    options: [],
    iw: 200
  },
  {
    field: "driver_name",
    label: "司机姓名",
    // type: "select-data-search",
    type: "input",
    defaultValue: null,
    props: {
      placeholder: "请输入司机姓名"
    },
    options: [],
    iw: 200
  }
];
